export const projects = [
    {
        title: "Google",
        subtitle: "with Observatory, 2022",
        desc: "Designed a set of furniture aimed at accommodating the hybrid work schedule in the Google office. Along with the accompanying trolley, blind, and signal, the desk offers an efficient and communal workstation for everyone.",
        images: [
            {
                path: "/images/google/GoogleDesk1.webp",
                alt: ""
            },
            {
                path: "/images/google/GoogleDesk2.webp",
                alt: ""
            },
            {
                path: "/images/google/GoogleDesk3.webp",
                alt: ""
            },
            {
                path: "/images/google/GoogleDesk4.webp",
                alt: ""
            },
            {
                path: "/images/google/GoogleDesk5.webp",
                alt: ""
            },
            {
                path: "/images/google/GoogleDesk6.webp",
                alt: ""
            },
            {
                path: "/images/google/GoogleDesk7.webp",
                alt: ""
            },
            {
                path: "/images/google/GoogleDesk8.webp",
                alt: ""
            },
            {
                path: "/images/google/GoogleDesk9.webp",
                alt: ""
            },
            {
                path: "/images/google/GoogleDesk10.webp",
                alt: ""
            },
            {
                path: "/images/google/GoogleDesk11.webp",
                alt: ""
            },
        ]
    },
    {
        title: "Herman Miller",
        subtitle: "with Observatory, 2022",
        desc: "Proposed a conference space that facilitates effective communication and participation for team members working both on-site and remotely to combat feelings of isolation in remote workers.",
        images: [
            {
                path: "/images/hermanmiller/HM1.webp",
                alt: ""
            },
            {
                path: "/images/hermanmiller/HM2.webp",
                alt: ""
            },
            {
                path: "/images/hermanmiller/HM3.webp",
                alt: ""
            },
            {
                path: "/images/hermanmiller/HM4.webp",
                alt: ""
            },
            {
                path: "/images/hermanmiller/HM5.webp",
                alt: ""
            },
            {
                path: "/images/hermanmiller/HM6.webp",
                alt: ""
            },
            {
                path: "/images/hermanmiller/HM7.webp",
                alt: ""
            },
            {
                path: "/images/hermanmiller/HM8.webp",
                alt: ""
            },
            {
                path: "/images/hermanmiller/HM9.webp",
                alt: ""
            },
            {
                path: "/images/hermanmiller/HM10.webp",
                alt: ""
            },
            {
                path: "/images/hermanmiller/HM11.webp",
                alt: ""
            },
            {
                path: "/images/hermanmiller/HM12.webp",
                alt: ""
            },
            {
                path: "/images/hermanmiller/HM13.webp",
                alt: ""
            },
        ]
    },
    {
        title: "SL5 & Luxe",
        subtitle: "with Shavelogic, 2023",
        desc: "Oversaw innovations and development of concepts and documentation for Shavelogic’s shaving products, branding, and packaging.",
        images: [
            {
                path: "/images/shavelogic/SL1.webp",
                alt: ""
            },
            {
                path: "/images/shavelogic/SL2.webp",
                alt: ""
            },
            {
                path: "/images/shavelogic/SL3.webp",
                alt: ""
            },
            {
                path: "/images/shavelogic/SL4.webp",
                alt: ""
            },
            {
                path: "/images/shavelogic/SL5.webp",
                alt: ""
            },
            {
                path: "/images/shavelogic/SL6.webp",
                alt: ""
            },
            {
                path: "/images/shavelogic/SL7.webp",
                alt: ""
            },
            {
                path: "/images/shavelogic/SL8.webp",
                alt: ""
            },
            {
                path: "/images/shavelogic/SL9.webp",
                alt: ""
            },
            {
                path: "/images/shavelogic/SL10.webp",
                alt: ""
            },
            {
                path: "/images/shavelogic/SL11.webp",
                alt: ""
            },
            {
                path: "/images/shavelogic/SL12.webp",
                alt: ""
            },
            {
                path: "/images/shavelogic/SL13.webp",
                alt: ""
            },
        ]
    },
    {
        title: "Cubby",
        subtitle: "Self-initiated, 2021",
        desc: "Optimized the desk area of industrial design students at RISD with a compact storage unit that is functional and catalogs the power tools, supplies, and miscellaneous scraps that otherwise clog the limited space.",
        images: [
            {
                path: "/images/cubby/Cubby1.webp",
                alt: ""
            },
            {
                path: "/images/cubby/Cubby2.webp",
                alt: ""
            },
            {
                path: "/images/cubby/Cubby3.webp",
                alt: ""
            },
            {
                path: "/images/cubby/Cubby4.webp",
                alt: ""
            },
            {
                path: "/images/cubby/Cubby5.webp",
                alt: ""
            },
            {
                path: "/images/cubby/Cubby6.webp",
                alt: ""
            },
            {
                path: "/images/cubby/Cubby7.webp",
                alt: ""
            },
            {
                path: "/images/cubby/Cubby8.webp",
                alt: ""
            },
            {
                path: "/images/cubby/Cubby9.webp",
                alt: ""
            },
            {
                path: "/images/cubby/Cubby10.webp",
                alt: ""
            },
            {
                path: "/images/cubby/Cubby11.webp",
                alt: ""
            },
            {
                path: "/images/cubby/Cubby12.webp",
                alt: ""
            },
            {
                path: "/images/cubby/Cubby13.webp",
                alt: ""
            },
        ]
    },
    {
        title: "Desk / Chair",
        subtitle: "Self-initiated, 2021",
        desc: "Fabricated a desk and a chair during the pandemic as a simple solution to uncluttering the workspace.",
        images: [
            {
                path: "/images/deskchair/ChairDesk1.webp",
                alt: ""
            },
            {
                path: "/images/deskchair/ChairDesk2.webp",
                alt: ""
            },
            {
                path: "/images/deskchair/ChairDesk3.webp",
                alt: ""
            },
            {
                path: "/images/deskchair/ChairDesk4.webp",
                alt: ""
            },
            {
                path: "/images/deskchair/ChairDesk5.webp",
                alt: ""
            },
            {
                path: "/images/deskchair/ChairDesk6.webp",
                alt: ""
            },
            {
                path: "/images/deskchair/ChairDesk7.webp",
                alt: ""
            },
            {
                path: "/images/deskchair/ChairDesk8.webp",
                alt: ""
            },
            {
                path: "/images/deskchair/ChairDesk9.webp",
                alt: ""
            },
            {
                path: "/images/deskchair/ChairDesk10.webp",
                alt: ""
            },
            {
                path: "/images/deskchair/ChairDesk11.webp",
                alt: ""
            },
        ]
    },
]