import { useState } from 'react';
import './App.css';
import { projects } from './projects';
import { ReactComponent as Logo } from './logo.svg';

function App() {
  const [selected, setSelected] = useState(null);
  const [darkMode, setDarkMode] = useState(true);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode)
  }

  return (
    <div className={darkMode ? "dark-app" : "app"}>

          <div className="main-content">
            <div className="main-header">
                <Logo id="logo" fill={darkMode ? "white" : "black"} onClick={toggleDarkMode} className="noselect"/>
            </div>
            <div id="filter-2" className={selected != null ? "filter-2" : ""}></div>
            <div id="filter-3" className={selected != null ? "filter-3" : ""}></div>
        
            <div id="desktop-scroll-group" className={darkMode ? "desktop-dark-background" : ""}>
              <p id="about-section" className={darkMode ? "dark-background" : ""}>
                <span>Sean Elrifai is a Boston-based industrial designer investigating the intersection of sincerity and utility. Studied at <a href={"https://www.risd.edu/"} target="_blank" rel="noopener noreferrer" className={`description-link ${darkMode ? "dark-description-link" : ""}`}>RISD</a> before cutting his teeth at <a href={"https://observatorydesign.com/"} target="_blank" rel="noopener noreferrer" className={`description-link ${darkMode ? "dark-description-link" : ""}`}>Observatory</a> and <a href={"https://www.shavelogic.com/"} target="_blank" rel="noopener noreferrer" className={`description-link ${darkMode ? "dark-description-link" : ""}`}>Shavelogic</a>.</span>
                <br/>
                <br/>
                <span><a href={"https://www.linkedin.com/in/sean-elrifai/"} target="_blank" rel="noopener noreferrer" className={`description-link ${darkMode ? "dark-description-link" : ""}`}>LinkedIn</a>, <a onClick={(e)=>e.stopPropagation()} href="mailto: hello@seanelrifai.com" className={`description-link ${darkMode ? "dark-description-link" : ""}`}>Email</a>, <a href={"https://drive.google.com/file/d/1N7LrtalKftOF4kmDfAzYLElIGHDDjYap/view"} target="_blank" rel="noopener noreferrer" className={`description-link ${darkMode ? "dark-description-link" : ""}`}>Resume</a></span>
              </p>
              <div className={`projects-container ${darkMode ? "dark-background" : ""}`}>
                {
                  projects.map((project, index) => (
                    <div className={`project-row ${darkMode ? "dark-project-row" : ""}`} onClick={()=>setSelected(index)}>
                      <div className="project-row-heading">
                        <p>
                          {index + 1}
                        </p>
                      
                        <p>
                          <span>{project.title}</span>
                          <br/>
                          <span>
                            {project.subtitle}
                          </span>
                        </p>
                      </div>
                      
                      <div className="project-row-images">
                        {
                          project.images.map(image => (
                            <img 
                              src={image.path} 
                              alt={image.alt} 
                              className="project-row-image"
                              loading="lazy"
                            />
                          ))
                        }
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          
        

      </div>

    {
      selected != null ? (
        <div id="project-overlay-wrapper" onClick={()=>setSelected(null)}>
          <div id="project-overlay" className={darkMode ? "dark-background" : ""}
            onClick={(e)=>e.stopPropagation()}
          >
            <div id="project-overlay-content">
              <p id="project-overlay-heading">
                <div id="project-overlay-heading-text">
                  <span>
                    <span>{projects[selected].title}</span>
                    <br/>
                    <span>{projects[selected].subtitle}</span>
                  </span>
                  <span id='close-button' onClick={()=>setSelected(null)}>
                    X
                  </span>
                </div>
                <p id="desktop-project-overlay-desc">{projects[selected].desc}</p>
              </p>
              <div className="project-overlay-images">
                {
                  projects[selected].images.map(image => (
                    <img 
                      src={image.path} 
                      alt={image.alt} 
                      className="project-overlay-image" 
                      loading="lazy"
                    />
                  ))
                }
                <p id="project-overlay-desc">{projects[selected].desc}</p>
              </div>
            </div>
          </div>
        </div>
      ) : <></>
    }      
    </div>
  );
}

export default App;
